<template>
  <y_layout>
    <router-view/>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";

export default {
  name: "index",
  components: {y_layout},
}
</script>

<style scoped>

.y-pointer:hover,
.active {
  color: #5D60F6;
}

.tab-box {
  border-radius: 10px 0 0 10px;
  border: 1px solid #ededed;
  font-size: 12px;
  background-color: #FFFFFF;
  width: 2rem;
  color: #888888;
  font-weight: 600;
}
</style>